<template>
  <div ref="view">
    <van-nav-bar
      v-if="information"
      :title="title"
      @click-left="clickNavBack"
      left-arrow
      :border="false"
    ></van-nav-bar>
    <div class="main_wrap" v-if="information">
        <div class="left">
          <div class="left_xz">考试须知:</div>
          <div class="left_text">
            <ol>
              <li v-for="item in notice" :key="item.index">{{ item.text }}</li>
            </ol>
          </div>
        </div>
        <div class="right">
          <div class="right_platform">驾校理论考试01号考台:</div>
          <div class="right_number">身份证号：{{ numberid }}</div>
          <div class="right_name">考生姓名：{{ nameid }}</div>
          <van-button class="right_button" color="linear-gradient(to top, #2db1ac, #00FFFF)" @click="examination()">开始考试</van-button>
          <div class="right_text">操作提示：每题考试答案确定后，点击选项，电脑立即判定所选答案，如选择错误，系统将提示正确答案，提示后不允许修改答案。</div>
        </div>
    </div>
    <div v-else style="overflow:inherit;">
      <div class="main_answer">
        <div class="navback" @click="NavBack">
          <img src="../../assets/icon/icon-xyh.png" alt="">
        </div>
        <div class="top">
          <div class="answer_left">
            <div class="answer_left_top">
              <span>第01考台</span>
            </div>
            <div class="answer_left_center">
              <div class="left_center_wrap">
                <div>考生信息</div>
                <img :src="picture.url" alt="">
                <div class="van-ellipsis">姓名：{{ picture.name }}</div>
                <div class="van-ellipsis">性别：{{ picture.sex }}</div>
                <div class="van-ellipsis">类型：{{ picture.type }}</div>
                <div class="van-ellipsis">科目：{{ picture.subject }}</div>
              </div>
            </div>
            <div class="answer_left_bottom">
              <span>倒计时</span>
              {{ pagetitle }}
            </div>
          </div>
          <div class="answer_center">
            <div class="answer_center_text">
              <span>{{ testingIdx+1 + "." }}{{ examTitles[testingIdx]? examTitles[testingIdx].questionStem : "-" }}</span>
              <div v-if="examTitles[testingIdx]" class="section-options">
                <div
                  v-for="(item, index) in examTitles[testingIdx].answers"
                  :key="index"
                  @click="chooseAnswer(index)"
                  :class="['option-item', { active: item.select }]"
                >
                  <span class="letter">{{ index | getLetter }}</span>
                  <span class="answer">{{ item.answerText }}</span>
                </div>
                <!-- <div class="determine" @click="testing" v-if="examTitles[testingIdx]?examTitles[testingIdx].questionType === 2:''">确定</div> -->
              </div>
            </div>
            <!-- <div class="answer_center_option">

            </div> -->
          </div>
          <div id="alltitle" class="answer_right">
          <!-- <div class="answer_right" v-if="subject==='1'"> -->
              <!-- <div @click="gotitle(index)" :class="[`topic-index ${index < testingIdx? rightAndYour[index].u === ''?'':'topic-correct':''}`, {active:index==testingIdx}]" v-for="(item,index) in examTitles" :key="index"> -->
              <div @click="gotitle(index)" :class="[`topic-index ${ rightAndYour[index].u === ''?'':'topic-correct' }`, { active:index==testingIdx }]" v-for="(item,index) in examTitles" :key="index">
                <span>{{ index+1 }}</span>
              </div>
          </div>
          <!-- <div class="answer_right" v-else>
              <div @click="gotitle(index)" :class="[`topic-indexs ${ rightAndYour[index].u === ''?'':'topic-correct'}`, {active:index==testingIdx}]" v-for="(item,index) in examTitles" :key="index">
                <span>{{index+1}}</span>
              </div>
          </div> -->
        </div>
        <div class="bottom">
          <div class="answer_tips">
            <div class="tips_text">
              <div>操作提示：{{ examTitles[testingIdx] ? questionType[examTitles[testingIdx].questionType] : "" }}</div>
              <div v-if="examTitles[testingIdx]!=undefined && examTitles[testingIdx].questionType === 3">本题为{{ questionType[examTitles[testingIdx].questionType] }}，请判断对错!</div>
              <div v-else-if="examTitles[testingIdx]!=undefined && examTitles[testingIdx].questionType === 2">本题为{{ questionType[examTitles[testingIdx].questionType] }}，请选择多项答案!</div>
              <div v-else-if="examTitles[testingIdx]!=undefined && examTitles[testingIdx].questionType === 1">本题为{{ questionType[examTitles[testingIdx].questionType] }}，请选择正确答案!</div>
            </div>
            <div class="btns-wrap">
              <van-button class="btns" type="primary" @click="prev">上一题</van-button>
              <van-button class="btns" type="primary" @click="next">下一题</van-button>
              <van-button class="btns" type="primary" @click="confirmSubimit">交卷</van-button>
            </div>
          </div>
          <div class="answer_img">
              <img :src="examTitles[testingIdx].questionPic" class="answer_img_picture" v-if="examTitles[testingIdx] && examTitles[testingIdx].questionPic" alt="" @click="preview">
              <img src="@/assets/vip/simulation-bj.png" class="answer_img_pictures" v-else alt="">
          </div>
        </div>
        <van-popup round v-model="volume">
          <div class="popup-content">
            <div class="content">
              确定交卷吗?
            </div>
            <div class="btns-wrap">
              <van-button @click="volumecancle" class="btns" type="primary">取消</van-button>
              <van-button @click="volumeconfirm" class="btns" type="primary">确定</van-button>
            </div>
          </div>
        </van-popup>
        <van-popup v-model="scores" style="border: 2px solid #2db1ac;">
          <div class="popup-content">
            <div class="content">
              满分{{ examInfo.examinationTotal }},合格分{{ examInfo.examinationPass }}<br>本次考试得分{{ score }}分<br>本次考试{{ score>=examInfo.examinationPass ? '合格' : '不合格' }}
            </div>
            <div class="btns-wrap" style="padding-left:50px">
              <van-button @click="clickNavBack" class="btns" type="primary">确定</van-button>
            </div>
          </div>
        </van-popup>
        <van-popup round v-model="showPop">
          <div class="popup-content">
            <div class="content">
              确定退出考试吗?
            </div>
            <div class="btns-wrap">
              <van-button @click="cancle" class="btns" type="primary">取消</van-button>
              <van-button @click="clickNavBack" class="btns" type="primary">确定</van-button>
            </div>
          </div>
        </van-popup>
        <!-- <viewer :images="handImg"> -->
        <viewer class="handimg" v-if="Picturepreview">
          <img :src="handImg" @click="previewimg">
        </viewer>
      </div>
    </div>
  </div>
</template>

<script>
import { getExamBefore,getExamBeforeInfo } from '../../api/user';
export default {
  name: "vipsimulation",
  data() {
    return {
      title: '',
      numberid: '632323190605269126',
      nameid: '训美在线',
      information: true,
      pagetitle: '', // 倒计时
      picture: {
        url : require('@/assets/icon-allright.png'),
        name : '训美1111111111111',
        sex : '男',
        type : '小车11111111111111',
        subject : '科目一'
      },
      notice: [
        {
          text: '1.遵守考场纪律,服从监考人员指挥。'
        },
        {
          text: '2.进入考场、手机关机。禁止抽烟，禁止吃零食。'
        },
        {
          text: '3.未经工作人员允许，考生禁止随意出入考场。'
        },
        {
          text: '4.考场内禁止大声喧哗，禁止随意走动。'
        },
        {
          text: '5.考试中认真答题，不准交头接耳。'
        },
        {
          text: '6.考试中不准冒名顶替，不准弄虚作假。'
        },
        {
          text: '7.注意考场卫生，禁止随地吐痰，禁止乱扔纸屑。'
        },
        {
          text: '8.爱护公物及考试设备。'
        },
      ],
      questionType: {
        1: "单选题",
        2: "多选题",
        3: "判断题",
      },
      showPop:false,
      volume:false,
      scores:false,
      testingIdx: 0,
      examTitles: [], // 题
      examInfo: {}, // 试题信息
      timer: null,
      answers: [], // 选项信息
      rightAndYour: [], // 储存用户选择的选项
      examinationId:0,
      score:0, // 分数
      num:100,
      handImg:[],
      Picturepreview:false,
      // imgInfo:{},
    };
  },
  components: {},
  created() {
    this.subject = this.$route.query.subject;
    this.categoryId = this.$route.query.categoryId;
    this.getExamBefore();
  },
  computed: {
    appno() {
      return this.$store.state.app.appno
    },
    token() {
      return JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token
    },
    appNo() {
      return sessionStorage.getItem('appno') || 'APP02'
    },
    rightAnswer() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let ans = '';
      this.answers.map((v,i) => {
        if (v.ifCorrenct==0) {
          ans += letters.charAt(i);
        }
      })
      return ans;
    },
    yourAnswer: {
      get(){
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let ans = '';
        this.answers.map((v,i) => {
          if (v.select) {
            ans += letters.charAt(i);
          }
        })
        return ans;
      },
      set(val){
        return val
      }
    }
  },
  mounted() {
    if (this.subject === '1') {
      this.title = '驾驶人科目一考试系统'
      this.num = 100
    } else {
      this.title = "驾驶人科目四考试系统"
      this.num = 50
    }
    document.getElementsByTagName("body")[0].className="add_bg"
    this.resizeScreen()
    for (var i = 0; i < this.num; i++) {
      var add = {
        r: this.rightAnswer,
        u: this.yourAnswer,
        check: '',
        t: '',
        i: i,
      };
      this.rightAndYour.push(add);
    }
    // 如果支持 popstate 一般移动端都支持了
    // if (window.history && window.history.pushState) {
    //   // 往历史记录里面添加一条新的当前页面的url
    //   history.pushState(null, null, document.URL);
    //   // 给 popstate 绑定一个方法 监听页面刷新
    //   window.addEventListener('popstate', this.goBack, false);//false阻止默认事件
    // }
  },
  // destroyed() {
	// 	window.removeEventListener('popstate', this.goBack, false);//false阻止默认事件
	// 	sessionStorage.setItem('isSign','1')
	// },
  methods: {
    // 限制返回键
    // goBack() {
    //   console.log("监听到了");
    //   history.pushState(null, null, document.URL);
    // },
    // 获取试题
    getExamBefore() {
      // 获取有几套题
      getExamBefore(
        this.categoryId,
        this.subject
      ).then((data) => {
        this.examinationId = data[parseInt(Math.random() * data.length)].basePkId
        // 随机抽取一套题
        getExamBeforeInfo(
          this.examinationId
        ).then((res) => {
          this.examInfo = res;
          this.examTitles = this.examInfo.questionManageEntityList;
          this.countDown()
        }).catch(() => {
        })
			}).catch(() => {
			})
    },
    // 图片放大
    preview() {
      this.Picturepreview = true;
      this.handImg = [this.examTitles[this.testingIdx].questionPic];
      // let img = new Image()
      // img.src = this.handImg
      // const vm = this
      // img.onload = function () {
      //   vm.$set(vm.imgInfo, 'width', img.width)
      //   vm.$set(vm.imgInfo, 'height', img.height)
      //   console.log(img.width/img.height) // 打印图片信息
      //   console.log(vm.imgInfo) // 打印图片信息
      // }
    },
    previewimg() {
      this.Picturepreview = false;
    },
    // 是否购买VIP并登录
    examination() {
      // if (JSON.parse(localStorage.getItem('loginInTKfor_APP02')) !== null && !JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId) {
      //   this.$dialog.alert({
      //     message: "请先去购买套餐",
      //     theme: "round-button",
      //     confirmButtonColor: "#2db1ac",
      //     width: 290,
      //   }).then(() => {
			// 		this.$router.push({ path: "/viplist",query: { appNo: this.appNo }});
			// 	});
      // } else {
      //   this.information = false
      // }
      this.information = false
    },
    // 选择答案
    chooseAnswer(index) { // 选择答案
      let answers = this.examTitles[this.testingIdx].answers;
      let type = this.examTitles[this.testingIdx].questionType;
      answers.map((v,i) => {
        let flag = !!v.select;
        if (type == 2) { // 多选
          if (i == index) v.select = !flag;
        } else { // 单选 判断
          if (i == index) {
            v.select = !flag;
          } else {
            v.select = false;
          }
        }
      })
      this.examTitles[this.testingIdx].answers = answers;
      this.answers = answers;
      this.$set(this.examTitles, this.testingIdx, this.examTitles[this.testingIdx]);
      this.$set(this.answers, index, this.answers[index]);
      this.testing();
    },
    // 跳到某一道题
    gotitle(index) {
      this.testingIdx = index;
      this.scrollbar();
    },
    scrollbar() {
      var Div = document.getElementById('alltitle');
      if (this.testingIdx >= 70) {
        Div.scrollTop = 100;
      } else {
        Div.scrollTop = 0;
      }
    },
    // 上一题
    prev() {
      if (this.testingIdx <= 0) {
        this.$dialog.alert({
          message: '已经是第一题了',
          theme: 'round-button',
          confirmButtonColor: '#2db1ac',
          width: 290,
        });
        return;
      }
      this.testingIdx--;
      this.scrollbar();
    },
    // 下一题
    next() {
      this.answers = this.examTitles[this.testingIdx].answers;
      const answer = this.answers.find(v => v.select)
      if (answer == undefined) {
        // this.rightAnswer = this.rightAnswer;
        this.yourAnswer = '';
        this.testing();
      }
      if (this.testingIdx >= this.examTitles.length-1) {
        this.$dialog.alert({
          message: '已经是最后一题了',
          theme: 'round-button',
          confirmButtonColor: '#2db1ac',
          width: 290,
        });
        return;
      }
      this.testingIdx++;
      this.scrollbar();
    },
    // 判断选项答案
    testing() {
      const answer = this.answers.find(v => v.select)
      if (answer == undefined) {
        this.yourAnswer = '';
      }
      var add = {
        r: this.rightAnswer,
        u: this.yourAnswer,
        check: this.rightAnswer==this.yourAnswer,
        t: this.examTitles[this.testingIdx].questionType,
        i: this.testingIdx,
      };
      if (this.rightAndYour[this.testingIdx] === null) {
        this.rightAndYour.push(add);
      } else {
        this.rightAndYour[this.testingIdx] = add;
      }
    },
    // 退出考试
    clickNavBack() {
      this.$router.go(-1);
    },
    // 点击交卷
    confirmSubimit() {
      this.volume = true;
    },
    // 取消交卷
    volumecancle() {
      this.volume = false;
    },
    // 确定交卷
    volumeconfirm() {
      this.handleSubmit();
    },
    // 判断分数
    handleSubmit() {
      this.volume = false;
      const scoreMap = {
        1: this.examInfo.singleChoiceScore,
        2: this.examInfo.multiChoiceScore,
        3: this.examInfo.judgeChoiceScore,
      }
      this.rightAndYour.map(v => {
        if (v.check) this.score += scoreMap[v.t];
      })
      this.scores = true;
    },
    // scoreconfirm() {
    //   this.$router.push({ path: "/viplist",query: {subject:this.subject,categoryId:this.categoryId}});
    // },
    // 点击回退
    NavBack() {
      this.showPop = true;
    },
    // 取消退出考试
    cancle() {
      this.showPop = false;
    },
    // confirm() {
    //   this.information = true;
    //   this.showPop = false;
    // },
    // 倒计时
    countDown() {
      let that = this;
      let time = {
        init: function () {
          // 小于10时补0
          function checkTime(i) {
            if (i < 10) {
              i = '0' + i
            }
            return i;
          }
          if (!that.examInfo ||!that.examInfo.examinationTime) return;
          let countTime = that.examInfo.examinationTime * 60;
          let h = Math.floor(countTime / 60 / 60);
          let m = Math.floor(countTime / 60 % 60);
          let s = countTime % 60;
          that.pageTitle = checkTime(h) + ":" + checkTime(m) + ":" + checkTime(s);

          //进行倒计时显示
          that.timer = setInterval(function () {
            --s;
            if (s < 0) {
              --m;
              s = 59;
            }
            if (m < 0) {
              --h;
              m = 59;
            }
            if (h < 0) {
              s = 0;
              m = 0;

              // handleSubject();
              clearInterval(that.timer);
              that.handleSubmit();
              return false;
            }
            that.pagetitle = checkTime(h) + ":" + checkTime(m) + ":" + checkTime(s);
          }, 1000);
        }
      }
      time.init();
    },
    // 转横屏
    resizeScreen() {
      const _this = this;
      // 利用 CSS3 旋转 对根容器逆时针旋转 90 度
      const detectOrient = function() {
        let width = document.documentElement.clientWidth,
          height = document.documentElement.clientHeight,
          $wrapper = _this.$refs.view, // 这里是页面最外层元素
          style = "";
        if (width >= height) {
          // 横屏
          style += "width:" + width + "px;"; // 注意旋转后的宽高切换
          style += "height:" + height + "px;";
          style += "-webkit-transform: rotate(0); transform: rotate(0);";
          style += "-webkit-transform-origin: 0 0;";
          style += "transform-origin: 0 0;";
        } else {
          // 竖屏
          style += "width:" + height + "px;";
          style += "height:" + width + "px;";
          style += "-webkit-transform: rotate(90deg); transform: rotate(90deg);";
          // 注意旋转中点的处理
          style += "-webkit-transform-origin: " + width / 2 + "px " + width / 2 + "px;";
          style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
          style += "margin-left: -2.5vw;";
        }
        $wrapper.style.cssText = style;
      };
      window.onresize = detectOrient;
      detectOrient();
    },
  }
}
</script>

<style lang="less" scoped>
.van-nav-bar {
  background-color: #2db1ac !important;
}
::v-deep .van-icon {
  color: #fff;
}
::v-deep .van-ellipsis {
  color: #fff;
}
.main_wrap{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.main_wrap .left{
  width: 40%;
  height: 5.84rem;
  border: 2px solid #2db1ac;
  background: #DCDCDC;
  padding-left: 0.1rem;
  overflow: scroll;
  &::-webkit-scrollbar{
    display: none;
  }
  .left_xz{
    font-size: 0.3rem;
    font-weight: 600;
    margin-top: 0.3rem;
  }
  .left_text{
    font-size: 0.18rem;
    ol li{
      margin-top: 0.26rem;
    }
  }
}
.main_wrap .right{
  width: 50%;
  height: 5.84rem;
  border: 2px solid #2db1ac;
  padding-left: 0.2rem;
  background: #DCDCDC;
  .right_platform{
    margin: 0.3rem 0;
  }
  .right_number{
    margin: 0.2rem 0;
    color: red;
    font-size: 0.24rem;
  }
  .right_name{
    margin: 0.2rem 0;
    color: red;
    font-size: 0.24rem;
  }
  .van-button{
    height: 0.84rem;
    width: 2rem;
  }
  .right_button{
    margin: 0.6rem 2rem 0.2rem;
    color: #fff;
    font-size: 0.3rem;
    font-weight: 700;
  }
  .right_text{
    margin: 0 0 1.2rem 0;
    line-height: 0.4rem;
    font-size: 0.24rem;
    padding-right: 0.2rem;
    color: #b0b0b0;
  }
}
.main_answer{
  width: 95%;
  height: 95vw;
  margin: auto;
  position: relative;
  .navback{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 20px;
    color: #fff;
    background-color: #8a8a8a;
    border-radius: 10px;
    img{
      width: 20px;
      height: 20px;
    }
  }
  .top{
    width: 100%;
    height: 60%;
    display: flex;
    .answer_left{
      width: 13%;
      height: 100%;
      .answer_left_top{
        height: 15%;
        border: #2db1ac solid 1px;
        font-size: 12px;
        text-align: center;
        line-height: 30px;
        span{
          transform: scale(0.8);
        }
      }
      .answer_left_center{
        height: 61%;
        margin-top: 8px;
        border: #2db1ac solid 1px;
        font-size: 12px;
        .left_center_wrap{
          transform: scale(0.8);
          position: relative;
          left: -5%;
          :nth-child(1){
            height: 15px;
            background-color: #fff;
            position: absolute;
            top: -20%;
            left: 10%;
          }
          img{
            width: 45px;
            margin-left: 20%;
          }
          :nth-child(3){
            height: 15px;
            margin-top: 5px;
            color: #000;
          }
          :nth-child(3):hover {
            text-overflow:inherit;
            overflow: visible;
            white-space: nowrap;
          }
          :nth-child(4){
            height: 15px;
            margin-top: 5px;
            color: #000;
          }
          :nth-child(5){
            height: 15px;
            margin-top: 5px;
            color: #000;
          }
          :nth-child(5):hover {
            text-overflow:inherit;
            overflow: visible;
            white-space: nowrap;
          }
          :nth-child(6){
            height: 15px;
            margin-top: 5px;
            color: #000;
          }
          :nth-child(6):hover {
            text-overflow:inherit;
            overflow: visible;
            white-space: nowrap;
          }
        }
      }
      .answer_left_bottom{
        height: 15%;
        margin-top: 8px;
        border: #2db1ac solid 1px;
        font-size: 12px;
        position: relative;
        text-align: center;
        line-height: 35px;
        span{
          height: 15px;
          background-color: #fff;
          position: absolute;
          top: -25%;
          left: 20%;
          line-height: 15px;
          transform: scale(0.8);
        }
      }
    }
    .answer_center{
      width: 60%;
      height: 100%;
      margin-left: 1%;
      font-size: 12px;
      .answer_center_text{
        height: 100%;
        border: 1px solid #2db1ac;
        padding: 10px 20px;
        .section-options{
          margin-top: 10px;
          .option-item{
            margin-bottom: 5px;
            color: #444444;
            .letter {
              display: inline-block;
              width: 20px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              font-size: 13px;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
              margin-right: 8px;
              border-radius: 20px;
            }
            &.active {
              .letter {
                color: #fff;
                background: #2db1ac;
              }
            }
          }
        }
      }
      // .answer_center_option{
      //   height: 20%;
      // }
    }
    .answer_right{
      width: 25%;
      height: 100%;
      margin-left: 1%;
      border: 1px solid #2db1ac;
      overflow: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
      .topic-index{
        display: inline-block;
        width: calc((100% - 10px) / 10);
        height: calc((100% - 10px) / 10);
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        text-align: center;
        color: #666;
        font-size: 10px;
        margin-top: 1px;
        margin-left: 1px;
        span{
          transform:scale(0.6);
          -webkit-transform:scale(0.6);
          display:inline-block;
        }
        &.active {
          background: #FFA500;
          color: #ffffff;
          border-color: transparent;
        }
      }
      .topic-indexs{
        display: inline-block;
        width: calc((100% - 5px) / 5);
        height: calc((100% - 10px) / 10);
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        text-align: center;
        line-height: 1.5;
        color: #666;
        font-size: 10px;
        margin-top: 1px;
        margin-left: 1px;
        span{
          transform:scale(0.6);
          -webkit-transform:scale(0.6);
          display:inline-block;
        }
        &.active {
          background: #FFA500;
          color: #ffffff;
          border-color: transparent;
        }
      }
      .topic-correct{
        background: #2db1ac;
      }
    }
  }
  .bottom{
    width: 100%;
    height: 40%;
    margin-top: 1px;
    .answer_tips{
      width: 100%;
      height: 30%;
      border: 1px solid #2db1ac;
      border-top: 2px solid #2db1ac;
      display: flex;
      .tips_text{
        width: 60%;
        margin: auto;
        font-size: 12px;
        :nth-child(1){
          color: red;
          margin-left: 45px;
        }
        :nth-child(2){
          margin: 0;
          transform: scale(0.8);
        }
      }
      .btns-wrap{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        .btns{
          width: 80px;
          height: 22px;
          margin-right: 5%;
          padding: 0;
          font-size: 12px;
          color: #000;
          border: 1px solid #2db1ac;
          background-color: #fff;
          border-radius: 11px;
        }
        :nth-child(2){
          color: #fff;
          background-color: #2db1ac;
        }
        :nth-child(3){
          color: orangered;
        }
      }
    }
    .answer_img{
      width: 100%;
      height: 70%;
      border: 1px solid #2db1ac;
      .answer_img_picture{
        width: 40%;
        height: 100%;
        margin-left: 30%;
      }
      .answer_img_pictures{
        width: 100%;
        height: 100%;
      }
    }
  }
  .popup-content{
    .content{
      text-align: center;
      padding-top: 20px;
    }
    .btns-wrap{
      width: 220px;
      height: 100px;
      display: flex;
      .btns{
        width: 80px;
        height: 40px;
        padding: 0;
        margin-top: 30px;
        margin-left: 20px;
        color: #000;
        border: 1px solid #2db1ac;
        background-color: #fff;
        border-radius: 11px;
      }
      :nth-child(2){
        color: #fff;
        background-color: #2db1ac;
      }
    }
  }
}
.handimg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5% 10%;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
